import { v4 as uuidv4 } from 'uuid'
import {
  AdGroupSplitConditionField,
  AdGroupSplitConditionOperators,
  AdGroupSplitConditionValue,
  AdGroupSplitData,
  AdGroupSplitsShortInfo,
} from '@lasso/api-activation/activation'
import { truthy } from '@lasso/shared/utils'

import { AdGroupLever } from './types'

export const buildLever = (bidValue: number | null = null): AdGroupLever => ({
  id: 0,
  localId: uuidv4(),
  name: '',
  bid: bidValue,
  targetBudget: null,
  creativeIds: [],
  deviceType: [],
  supplyType: [],
  viewability: null,
  inventoryList: [],
  targetingBlock: null,
})

export const leverFromApiSplit = (split: AdGroupSplitsShortInfo): AdGroupLever => {
  const result: AdGroupLever = {
    id: split.id!,
    localId: uuidv4(),
    name: split.name!,
    bid: split.bid!,
    targetBudget: split.targetBudget!,
    creativeIds: split.creativeIds!,
    deviceType: [],
    supplyType: [],
    viewability: null,
    inventoryList: [],
    targetingBlock: null,
  }

  split.conditions?.forEach((condition) => {
    switch (condition.field) {
      case AdGroupSplitConditionField.DEVICE_TYPE:
        result.deviceType = condition.value
        break
      case AdGroupSplitConditionField.INVENTORY_TYPE:
        result.supplyType = condition.value
        break
      case AdGroupSplitConditionField.PREDICTED_IAB_VIEW_RATE:
        result.viewability = parseInt(condition.value[0]!) // Inconsistency with  AdGroupSplitConditionValue Enum caused by API limitation and backward compatibility with V1
        break
      case AdGroupSplitConditionField.INVENTORY_URL_LIST:
        result.inventoryList = condition.value.map(id => ({
          id: parseInt(id, 10), // Inconsistency with AdGroupSplitConditionValue Enum caused by API limitation and backward compatibility with V1
          operator: condition.operator!,
        }))
        break
    }
  })

  return result
}

export const leverToApiSplit = (lever: AdGroupLever, order: number): AdGroupSplitData => {
  const result: AdGroupSplitData = {
    id: lever.id,
    name: lever.name,
    splitOrder: order,
    bid: lever.bid ?? undefined,
    targetBudget: lever.targetBudget!,
    creativeIds: lever.creativeIds,
    conditions: [],
  }

  if (lever.deviceType.length) {
    result.conditions!.push({
      name: 'Device Type',
      field: AdGroupSplitConditionField.DEVICE_TYPE,
      operator: AdGroupSplitConditionOperators.IN,
      value: lever.deviceType.filter(truthy),
      resName: lever.deviceType.join(','),
    })
  }

  if (lever.supplyType.length) {
    result.conditions!.push({
      name: 'Supply Type',
      field: AdGroupSplitConditionField.INVENTORY_TYPE,
      operator: AdGroupSplitConditionOperators.IN,
      value: lever.supplyType.filter(truthy),
      resName: lever.supplyType.join(','),
    })
  }

  if (lever.viewability) {
    result.conditions!.push({
      name: 'Viewability Minimum',
      field: AdGroupSplitConditionField.PREDICTED_IAB_VIEW_RATE,
      operator: AdGroupSplitConditionOperators.GREATER_EQUAL,
      value: [lever.viewability.toString() as AdGroupSplitConditionValue], // Inconsistency with  AdGroupSplitConditionValue Enum caused by API limitation and backward compatibility with V1
      resName: `${lever.viewability}%`,
    })
  }

  /*
    By the current requirements, only one inventory list can be selected within the lever at a time, and the UI prevents choosing more than one element.
    If the requirements change, the following code should be modified depending on the new requirements and updated API contract.
  */
  if (lever.inventoryList.length) {
    result.conditions!.push({
      name: 'Inventory List',
      field: AdGroupSplitConditionField.INVENTORY_URL_LIST,
      operator: lever.inventoryList[0]!.operator!,
      value: lever.inventoryList.map(({ id }) => id.toString() as AdGroupSplitConditionValue), // Inconsistency with  AdGroupSplitConditionValue Enum caused by API limitation and backward compatibility with V1
      resName: 'Inventory List test',
    })
  }

  return result
}

export const getLeversTotalBudget = (levers: { targetBudget: number | null }[]): number => {
  return levers.reduce((acc, lever) => acc + (lever.targetBudget ?? 0), 0)
}
