import { useApiManual } from '@lasso/shared/hooks'
import { useActivationApi } from '@lasso/api-activation/activation'

import { AdGroupLever } from './types'
import { leverToApiSplit } from './utils'

export const useCardLeversSubmit = () => {
  const { requestThrows: updateLeversInternal } = useApiManual(useActivationApi().linkLevers)

  const updateLevers = async ({ levers, adGroupId }: {
    levers: AdGroupLever[]
    adGroupId: number
  }) => {
    const activeSplits = levers.map((lever, index) => leverToApiSplit(lever, index + 1))

    await updateLeversInternal({
      adGroupID: adGroupId,
      adGroupSplitsArray: [
        ...activeSplits,
      ],
    })
  }

  return { updateLevers }
}
