<template>
  <Draggable v-model="levers" spaceY="4">
    <Box v-for="(lever, index) in levers" :key="lever.localId" flex wrap="nowrap" alignItems="center" spaceX="2">
      <Icon icon="drag_indicator" class="cursor-move" data-role="handle" />

      <Paper variant="filled" color="base" px="6" py="4" rounded="xl" flex col spaceY="4" grow="grow">
        <Box flex justify="between">
          <Chip size="2xs" color="secondary" :label="`Lever ${index + 1}`" />

          <Box flex spaceX="2" ml="auto">
            <ButtonIcon icon="mode_edit" @click="emit('showDrawer', lever, null)" />
            <ButtonIcon v-if="levers.length > 2" icon="close" @click="removeLever(index)" />
          </Box>
        </Box>

        <Box flex spaceX="6" spaceY="4" wrap="wrap" maxWidth="952px">
          <FormField
            :id="getId(`levers.levers[${index}].name`)"
            v-slot="{ id }"
            label="Lever name"
            width="220px"
            required
          >
            <InputText
              :id="id"
              :modelValue="levers[index]!.name"
              @update:modelValue="updateLever(index, { name: $event })"
            />
          </FormField>

          <FormField
            v-if="!isDirectSold"
            :id="getId(`levers.levers[${index}].bid`)"
            v-slot="{ id }"
            label="Media Bid"
            width="220px"
            :disabled="isOptimizationTypeSelected"
            required
          >
            <Tooltip
              v-if="isOptimizationTypeSelected"
              placement="top"
              minWidth="100%"
              pr="2"
              content="When an automated optimization is selected above, Media OS will automatically set bids for you based on your optimization goal."
            >
              <InputText disabled currency :modelValue="levers[index]!.bid" />
            </Tooltip>
            <InputText
              v-else
              :id="id"
              :modelValue="levers[index]!.bid"
              :mask="decimalMask"
              currency
              @update:modelValue="updateLever(index, { bid: $event })"
            />
          </FormField>

          <FormField
            :id="getId(`levers.levers[${index}].targetBudget`)"
            v-slot="{ id }"
            label="Spend Allocator"
            width="220px"
            required
          >
            <InputText
              :id="id"
              :modelValue="levers[index]!.targetBudget"
              :mask="numberMask"
              endIcon="percent"
              @update:modelValue="updateLever(index, { targetBudget: $event })"
            />
          </FormField>

          <FormField
            :id="getId(`levers.levers[${index}].creativeIds`)"
            v-slot="{ error }"
            label="Creatives"
            width="220px"
            required
            @click="emit('showDrawer', lever, 'creatives')"
          >
            <Tooltip :key="getDetailedCreatives(index).length" placement="top" minWidth="100%" pr="2">
              <template v-if="getDetailedCreatives(index).length > 0" #content>
                <Box flex col spaceY="2" width="220px">
                  <Typography
                    v-for="creative in getDetailedCreatives(index)"
                    :key="creative.id"
                    variant="tooltip1"
                    color="textPrimary"
                    truncate
                    display="block"
                  >
                    {{ creative.name }}
                  </Typography>
                </Box>
              </template>
              <InputText :error="error" :modelValue="`${levers[index]!.creativeIds.length} selected`" readonly />
            </Tooltip>
          </FormField>

          <template v-if="!isDirectSold && !isEndemicChannel">
            <FormField :id="getId(`levers.levers[${index}].deviceType`)" v-slot="{ id }" label="Device type" width="220px">
              <Select
                :id="id"
                :modelValue="levers[index]!.deviceType"
                :options="deviceTypeOptions"
                name="deviceType"
                placeholder="Select"
                multiple
                @update:modelValue="updateLever(index, { deviceType: $event })"
              />
            </FormField>

            <FormField :id="getId(`levers.levers[${index}].supplyType`)" v-slot="{ id }" label="Supply type" width="220px">
              <Select
                :id="id"
                :modelValue="levers[index]!.supplyType"
                :options="supplyTypeOptions"
                placeholder="Select"
                multiple
                @update:modelValue="updateLever(index, { supplyType: $event })"
              />
            </FormField>

            <FormField :id="getId(`levers.levers[${index}].viewability`)" v-slot="{ id }" label="Viewability minimum" width="220px">
              <InputText
                :id="id"
                :modelValue="levers[index]!.viewability"
                :mask="numberMask"
                endIcon="percent"
                @update:modelValue="updateLever(index, { viewability: $event })"
              />
            </FormField>

            <FormField
              :id="getId(`levers.levers[${index}].inventoryList`)"
              v-slot="{ error }"
              label="Inventory Lists"
              width="220px"
              @click="emit('showDrawer', lever, 'inventoryLists')"
            >
              <Tooltip :key="getDetailedInventory(index).length" placement="top" minWidth="100%" pr="2">
                <template v-if="getDetailedInventory(index).length > 0" #content>
                  <Box flex col spaceY="2" width="220px">
                    <Typography
                      v-for="list in getDetailedInventory(index)" :key="list.id" variant="tooltip1" color="textPrimary"
                      truncate display="block"
                    >
                      {{ list.name }}
                    </Typography>
                  </Box>
                </template>
                <InputText
                  :error="error"
                  :modelValue="`${getDetailedInventory(index).length} selected`"
                  readonly
                />
              </Tooltip>
            </FormField>
          </template>
        </Box>
        <FormError :id="getId(`levers.levers[${index}].targetingBlock`)" />
      </Paper>
    </Box>
  </Draggable>
</template>

<script setup lang="ts">
import {
  Box,
  ButtonIcon,
  Chip, Draggable,
  FormError, FormField,
  Icon,
  InputText,
  Paper,
  Select,
  Tooltip,
  Typography,
} from '@lasso/luikit'
import { decimalMask, numberMask } from '@lasso/shared/consts'

import { useCardLevers } from './useCardLevers'
import { AdGroupLever } from './types'
import { LeverEditPanelScrollTarget } from './LeverDrawer'

const emit = defineEmits<{
  'showDrawer': [AdGroupLever, LeverEditPanelScrollTarget | null]
}>()

const {
  getId,
  levers,
  updateLever,
  removeLever,
  isOptimizationTypeSelected,
  getDetailedCreatives,
  getDetailedInventory,
  isDirectSold,
  isEndemicChannel,
  deviceTypeOptions,
  supplyTypeOptions,
} = useCardLevers()!
</script>
