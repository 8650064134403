<template>
  <Box
    v-if="adGroupSelectedCreatives.length === 0"
    minHeight="132px"
    flex
    col
    spaceY="4"
    alignItems="center"
    justify="center"
  >
    <Paper
      color="secondary-light"
      rounded="xl"
      flex
      justify="center"
      alignItems="center"
      height="48px"
      width="48px"
    >
      <Icon icon="warning" size="lg" variant="outlined" color="secondary" />
    </Paper>
    <Typography variant="body2" alignText="center">
      No creatives were selected for the Ad Group. <br> Please add these in the Creatives tab.
    </Typography>
  </Box>

  <Box v-else flex col spaceY="10">
    <InputText
      v-model="searchQuery"
      placeholder="Search for a creative"
      icon="search"
    />
    <Table
      ref="tableRef"
      v-model:selected="leverSelectedCreativesIds"
      tableLayout="auto"
      :fields="tableColumns"
      :data="filteredCreatives"
      hoverable
      stickyFirstColumn
      checkbox
    >
      <template #name="{ value, row: creative, hovered }">
        <CellName v-bind="{ value, creative, hovered }" />
      </template>

      <template #creativeStatus="{ rawValue }">
        <Chip
          size="2xs"
          variant="outlined"
          :color="creativeStatusChipMap[rawValue].color"
          :label="creativeStatusChipMap[rawValue].label"
        />
      </template>
    </Table>
  </Box>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import {
  Box,
  Chip,
  Icon,
  InputText,
  Paper,
  Table,
  TableColumn,
  Typography,
} from '@lasso/luikit'

import { AdGroupCreative, creativeStatusChipMap } from '../../../../shared/creatives'

import CellName from './CellName.vue'

const props = defineProps<{
  leverCreativesIds: number[]
  adGroupSelectedCreatives: AdGroupCreative[]
}>()

const emits = defineEmits<{
  'update:leverCreativesIds': [number[]]
}>()

const tableColumns: TableColumn<AdGroupCreative>[] = [
  {
    id: 'name',
    label: 'Creative',
  },
  {
    id: 'creativeStatus',
    label: 'Status',
    minWidth: '145px',
  },
]

const searchQuery = ref('')

const leverSelectedCreativesIds = computed({
  get: () => props.leverCreativesIds.map(String),
  set: (value) => {
    emits('update:leverCreativesIds', value.map(Number))
  },
})

const filteredCreatives = computed(() => {
  const query = searchQuery.value.toLowerCase().trim()

  if (!query) {
    return props.adGroupSelectedCreatives
  }

  return props.adGroupSelectedCreatives.filter((item) => {
    return item.name!.toLowerCase().includes(query) || String(item.id) === query
  })
})
</script>
