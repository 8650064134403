<template>
  <form ref="formRef" @submit="onSubmit($event)">
    <Card title="Overview" shadow="none" variant="filled" py="4" mb="6" disablePY opened collapse>
      <Box pt="6" flex space="6">
        <FormField :id="getId('name')" v-slot="{ id }" required label="Lever name" width="220px">
          <InputText :id="id" v-model="fields.name" />
        </FormField>

        <FormField
          v-if="!isDirectSold"
          :id="getId('bid')"
          v-slot="{ id }"
          required
          label="Media Bid"
          width="220px"
          :disabled="isOptimizationTypeSelected"
        >
          <Tooltip
            v-if="isOptimizationTypeSelected"
            placement="top"
            minWidth="100%"
            pr="2"
            content="When an automated optimization is selected above, Media OS will automatically set bids for you based on your optimization goal."
          >
            <InputText disabled currency :modelValue="fields.bid" />
          </Tooltip>

          <InputText v-else :id="id" v-model="fields.bid" :mask="decimalMask" currency />
        </FormField>

        <FormField :id="getId('targetBudget')" v-slot="{ id }" required label="Spend Allocator" width="220px">
          <InputText :id="id" v-model="fields.targetBudget" :mask="numberMask" endIcon="percent" />
        </FormField>
      </Box>
    </Card>

    <Card
      v-if="!isDirectSold && !isEndemicChannel"
      ref="targetingCardRef"
      title="Targeting"
      shadow="none"
      variant="filled"
      py="4"
      mb="6"
      disablePY
      opened
      collapse
    >
      <Box pt="6" flex direction="col" spaceY="6">
        <FormSection :id="getId('deviceType')" v-slot="{ id }" title="Select device type">
          <Box flex row wrap="wrap" space="6">
            <CheckboxButton
              v-for="option in deviceTypeOptions"
              :key="option.value"
              v-model="fields.deviceType"
              :value="option.value"
              :name="id"
              :title="option.label"
              :icon="option.icon"
            />
          </Box>
        </FormSection>

        <FormSection :id="getId('supplyType')" v-slot="{ id }" title="Select supply type">
          <Box flex row wrap="wrap" space="6">
            <CheckboxButton
              v-for="option in supplyTypeOptions"
              :key="option.value"
              v-model="fields.supplyType"
              :value="option.value"
              :name="id"
              :title="option.label"
              :icon="option.icon"
            />
          </Box>
        </FormSection>

        <FormSection title="Add viewability">
          <FormField :id="getId('viewability')" v-slot="{ id }" label="Viewability minimum" width="220px">
            <InputText :id="id" v-model="fields.viewability" :mask="numberMask" endIcon="percent" />
          </FormField>
        </FormSection>

        <FormSection title="Select inventory">
          <LeverInventoryListsSelector
            selectedItemsLabel="Selected Lists"
            :listItems="inventory"
            :selectedListItems="inventoryListDetailed"
            :disableNotSelectedItems="inventoryListDetailed.length >= 1"
            @listItemSelected="onInventoryListSelect"
            @removeSelectedItem="onInventoryListRemove"
          />
        </FormSection>
      </Box>
    </Card>

    <Card ref="creativesCardRef" title="Creatives" shadow="none" variant="filled" py="4" mb="6" disablePY opened collapse>
      <Box pt="6" flex direction="col" spaceY="6">
        <LeverCreatives
          v-model:leverCreativesIds="fields.creativeIds"
          :adGroupSelectedCreatives="selectedCreatives"
        />
      </Box>
    </Card>

    <Box flex justify="end" space="2" mt="6">
      <Button size="lg" variant="outlined" @click="hideLeverDrawer()">
        Cancel
      </Button>
      <Button type="submit" color="primary" size="lg">
        Save Lever
      </Button>
    </Box>
  </form>
</template>

<script setup lang="ts">
import {
  Box,
  Button,
  Card,
  CheckboxButton,
  FormField,
  FormSection,
  InputText,
  Tooltip,
} from '@lasso/luikit'
import { ComponentPublicInstance, computed, onMounted, reactive, ref } from 'vue'
import { AdGroupSplitConditionOperators, InventoryListShortInfo, InventoryListType } from '@lasso/api-activation/activation'
import { decimalMask, numberMask } from '@lasso/shared/consts'
import { useBetterForm } from '@lasso/shared/hooks'

import { useCardLevers } from '../useCardLevers'
import { AdGroupLever } from '../types'

import { buildLeverFormSchema } from './buildLeverFormSchema'
import LeverCreatives from './LeverCreatives.vue'
import LeverInventoryListsSelector from './LeverInventoryListsSelector.vue'
import { useLeverDrawer } from './useLeverDrawer'

const creativesCardRef = ref<ComponentPublicInstance>()
const targetingCardRef = ref<ComponentPublicInstance>()

const {
  levers,
  inventory,
  selectedCreatives,
  isOptimizationTypeSelected,
  isDirectSold,
  isEndemicChannel,
  channelId,
  deviceTypeOptions,
  supplyTypeOptions,
} = useCardLevers()!
const { editableLever, drawerScrollTarget, saveLever, hideLeverDrawer } = useLeverDrawer()!

const validationSchema = computed(() => {
  return buildLeverFormSchema({
    levers: levers.value,
    optimizationSelected: isOptimizationTypeSelected.value,
    isDirectSold,
    channelId: channelId.value,
  })
})

const {
  useFieldModels,
  handleSubmit,
  formRef,
  getId,
} = useBetterForm({
  validationSchema,
  initialValues: ref(editableLever.value as AdGroupLever),
})

const fields = reactive(useFieldModels([
  'id',
  'localId',
  'name',
  'bid',
  'targetBudget',
  'viewability',
  'creativeIds',
  'deviceType',
  'supplyType',
  'inventoryList',
]))

const inventoryListDetailed = computed(() => inventory.value.filter(item => fields.inventoryList.find(({ id }) => id === item.id)))

const onInventoryListSelect = (item: InventoryListShortInfo) => {
  if (fields.inventoryList.find(({ id }) => id === item.id)) {
    fields.inventoryList = fields.inventoryList.filter(({ id }) => id !== item.id)
  }
  else {
    fields.inventoryList = [...fields.inventoryList, {
      id: item.id,
      operator: item.inventoryListType === InventoryListType.WHITELIST
        ? AdGroupSplitConditionOperators.ALL
        : AdGroupSplitConditionOperators.NOT_IN,
    }]
  }
}

const onInventoryListRemove = (item: InventoryListShortInfo) => {
  fields.inventoryList = fields.inventoryList.filter(({ id }) => id !== item.id)
}

const onSubmit = handleSubmit(values => saveLever(values))

const scrollToElement = (element?: HTMLElement) => {
  setTimeout(() => {
    element?.scrollIntoView({
      behavior: 'smooth',
    })
  }, 300)
}

onMounted(() => {
  switch (drawerScrollTarget.value) {
    case 'inventoryLists':
      scrollToElement(targetingCardRef.value?.$el)
      break
    case 'creatives':
      scrollToElement(creativesCardRef.value?.$el)
      break
    default:
      break
  }
})
</script>
