<template>
  <TableCellName
    :hovered="hovered || previewShown"
    :href="previewUrl"
    target="_blank"
    :clickable="Boolean(previewUrl)"
  >
    <template #chip>
      <Chip size="2xs" variant="outlined" :label="creative.id" />
    </template>
    {{ value }}
    <template #actions>
      <CreativePreviewButton
        v-model:shown="previewShown"
        :creative="creative"
        :href="previewUrl"
        :channelId="channelId"
      />
    </template>
  </TableCellName>
</template>

<script setup lang="ts">
import { Chip, TableCellName } from '@lasso/luikit'
import { computed, ref } from 'vue'

import { CreativePreviewButton } from '../../../shared/CreativesPreviewButton'
import { AdGroupCreative } from '../../../../shared'
import { useCardLevers } from '../useCardLevers'

const { value, creative, hovered } = defineProps<{
  value: string
  creative: AdGroupCreative
  hovered: boolean
}>()

const { getCreativePreviewUrl, channelId } = useCardLevers()!

const previewShown = ref(false)
const previewUrl = computed(() => getCreativePreviewUrl(creative))
</script>
